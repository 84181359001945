import { Typography } from '@mui/material';
import { useLocales } from 'locales';
import { loginPI } from 'overrides/images/Login';
import { BannerLoginWrapper } from './style';

// ----------------------------------------------------------------

const BannerLogin = () => {
  const { translate } = useLocales();

  return (
    <BannerLoginWrapper
      sx={{
        backgroundImage: `url(${loginPI})`,
      }}
    >
      <Typography variant="h4">{translate('sign_up_now')}</Typography>
      <Typography component="span">
        {translate('to_get')} <Typography component="span">$3</Typography>
      </Typography>
    </BannerLoginWrapper>
  );
};

export { BannerLogin };
