import { SvgIcon } from '@mui/material';

export function LogoPiMB(props: any) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 84.91 85.16"
      fill="none"
      {...props}
    >
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            className="cls-1"
            d="M84.86,37.48A42.58,42.58,0,1,0,36.7,84.76a46.37,46.37,0,0,0,5.89.4,45.69,45.69,0,0,0,5.22-.31V55c0-.86,0-.86.87-.86h6.13c.91,0,.91,0,.91.89V83.1a42.52,42.52,0,0,0,11.11-5.51V55c0-.87,0-.87.87-.87h7.09a10.51,10.51,0,0,0,10-9.12C85,42.58,84.88,37.54,84.86,37.48ZM51,43H29.45a10.51,10.51,0,0,0-10.68,9.33,65.21,65.21,0,0,0-.12,7.13c0,.47.33.41.61.41h9.87c.52,0,.64-.2.63-.67V54.79c0-.52.16-.7.69-.69h5.49c.62,0,.79.19.79.79V73.63a31.59,31.59,0,1,1,37.09-36c0,.08,1.08,5.37-.84,5.37Z"
            fill="#f2d500"
          />
          <circle
            className="cls-1"
            cx="42.21"
            cy="33.78"
            r="5.5"
            fill="#f2d500"
          />
          <circle
            className="cls-1"
            cx="60.27"
            cy="33.78"
            r="5.5"
            fill="#f2d500"
          />
        </g>
      </g>
    </SvgIcon>
  );
}
