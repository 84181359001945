import { LogoPi } from 'assets/icons/LogoPi';

const LogoLoading = () => (
  <LogoPi
    disabledLink
    style={{
      width: 150,
    }}
  />
);
export { LogoLoading };
