import { IconFBFooter, IconTelegram, IconTwitterX } from 'assets/icons';
import { SOCIAL_COMMUNITY_LINK } from 'constants/index';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import { Box } from '@mui/material';
import { FooterContactWrapper } from './style';

// ----------------------------------------------------------------

const FooterContact = () => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <FooterContactWrapper>
      <Box
        component="li"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <IconTelegram />

        {isHovered && (
          <Box>
            <Link to={SOCIAL_COMMUNITY_LINK.TELEGRAM_CHANEL_PI} target="_blank">
              Chanel
            </Link>
            <Link to={SOCIAL_COMMUNITY_LINK.TELEGRAM_GROUP_PI} target="_blank">
              Group
            </Link>
          </Box>
        )}
      </Box>

      <Box component="li">
        <Link to={SOCIAL_COMMUNITY_LINK.TWITTER_PI} target="blank">
          <IconTwitterX />
        </Link>
      </Box>

      <Box component="li">
        <Link to={SOCIAL_COMMUNITY_LINK.FACEBOOK_PI} target="blank">
          <IconFBFooter />
        </Link>
      </Box>
    </FooterContactWrapper>
  );
};

export { FooterContact };
