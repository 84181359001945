import { ReactComponent as CloseCircle } from 'assets/icons/close-circle.svg';
import { useAuthContext } from 'auth/useAuthContext';
import Button from 'common/Button';
import { DialogAnimate } from 'components/animate';
import ReactGA from 'react-ga4';

import { Box, useTheme } from '@mui/material';

import { useLocales } from 'locales';
import { SignUpPiWrapper } from './style';

// -------------------------------------------------------------------

interface Props {
  open: boolean;
  onClose: VoidFunction;
}

const PopupBonus = ({ open, onClose }: Partial<Props>) => {
  const theme = useTheme();
  const { translate } = useLocales();
  const { openLoginModal } = useAuthContext();

  const handleSignUpClick = () => {
    openLoginModal();
    ReactGA.event({
      category: 'Popup Pi Click',
      action: 'Button Play Now popup Dice Trio Pi click',
    });
  };

  return (
    <DialogAnimate
      open={open}
      onClose={onClose}
      sx={{
        height: 'fit-content',
        [theme.breakpoints.down('md')]: {
          width: 500,
        },
        [theme.breakpoints.down('sm')]: {
          width: 400,
        },
        [theme.breakpoints.down(450)]: {
          width: 300,
        },
      }}
    >
      <SignUpPiWrapper>
        <Box>
          <CloseCircle onClick={onClose} />
        </Box>

        <Button type="button" onClick={() => handleSignUpClick()}>
          {translate('play_now')}
        </Button>
      </SignUpPiWrapper>
    </DialogAnimate>
  );
};

export { PopupBonus };
