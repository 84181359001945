import { SvgIcon } from '@mui/material';

export function LogoPi(props: any) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 379.38 85.15"
      fill="none"
      {...props}
    >
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            className="cls-1"
            d="M100.35,76a6.16,6.16,0,0,1-1.68-4.56V34.72a5.81,5.81,0,0,1,1.51-4.32,5.83,5.83,0,0,1,4.32-1.51h17.57q8,0,12.49,4.08T139,44.33q0,7.2-4.49,11.32t-12.46,4.12H111.3V71.44A6.11,6.11,0,0,1,109.58,76a6.38,6.38,0,0,1-4.67,1.68A6.16,6.16,0,0,1,100.35,76Zm20-25.7q3.49,0,5.18-1.44a5.56,5.56,0,0,0,1.68-4.46q0-6-6.86-6H111.3V50.3Z"
            fill="#f2d500"
          />
          <path
            className="cls-1"
            d="M146.81,76a6.16,6.16,0,0,1-1.68-4.56V34.72a6.1,6.1,0,0,1,1.68-4.5,6.19,6.19,0,0,1,4.57-1.68,6.4,6.4,0,0,1,4.67,1.68,6,6,0,0,1,1.71,4.5V71.44A6.1,6.1,0,0,1,156.05,76a6.4,6.4,0,0,1-4.67,1.68A6.19,6.19,0,0,1,146.81,76Z"
            fill="#f2d500"
          />
          <path
            className="cls-1"
            d="M166.72,75.76a6.88,6.88,0,0,1,0-9.47,6.41,6.41,0,0,1,4.77-1.92,6.33,6.33,0,0,1,4.7,1.92,6.88,6.88,0,0,1,0,9.47,6.33,6.33,0,0,1-4.7,1.92A6.41,6.41,0,0,1,166.72,75.76Z"
            fill="#f2d500"
          />
          <path
            className="cls-1"
            d="M224.23,51.23a4.46,4.46,0,0,1,1.34,3.39V66.57a20.68,20.68,0,0,1-.51,5.25,5.31,5.31,0,0,1-1.86,2.84,11.9,11.9,0,0,1-4,1.79,44.35,44.35,0,0,1-5.94,1.06,53.39,53.39,0,0,1-6.07.38,29.45,29.45,0,0,1-13.7-3,20.76,20.76,0,0,1-8.88-8.57A27.08,27.08,0,0,1,181.51,53,26.76,26.76,0,0,1,184.6,39.9a21.06,21.06,0,0,1,8.78-8.61,28.09,28.09,0,0,1,13.32-3,30.39,30.39,0,0,1,8.61,1.13,24,24,0,0,1,7.1,3.47,5.28,5.28,0,0,1,1.68,1.78,5.14,5.14,0,0,1,.52,2.47,5.65,5.65,0,0,1-1.17,3.61,3.48,3.48,0,0,1-2.81,1.47,5.13,5.13,0,0,1-1.89-.31,13.05,13.05,0,0,1-2-1.06,23.07,23.07,0,0,0-4.77-2,18.17,18.17,0,0,0-4.84-.62q-6.53,0-9.71,3.64T194.2,53q0,7.77,3.33,11.5t10.2,3.74a31.87,31.87,0,0,0,6.52-.68v-9H209.3a5,5,0,0,1-3.5-1.13,4.75,4.75,0,0,1,0-6.38A5,5,0,0,1,209.3,50h11.33A5,5,0,0,1,224.23,51.23Z"
            fill="#f2d500"
          />
          <path
            className="cls-1"
            d="M280.75,72.06a4.91,4.91,0,0,1-1.82,3.84,6.17,6.17,0,0,1-4.15,1.58,5.29,5.29,0,0,1-2.88-.83,5.47,5.47,0,0,1-2.06-2.47l-3.09-6.93H244.51l-3.15,6.93a5.41,5.41,0,0,1-5,3.3,6.44,6.44,0,0,1-4.22-1.58,4.85,4.85,0,0,1-1.88-3.84,5.91,5.91,0,0,1,.61-2.54L249,32.32a6.37,6.37,0,0,1,2.65-2.85,7.72,7.72,0,0,1,3.87-1,7.81,7.81,0,0,1,3.85,1A6.25,6.25,0,0,1,262,32.32l18.12,37.2A5.92,5.92,0,0,1,280.75,72.06ZM262.49,57.78l-6.86-15.3-6.86,15.3Z"
            fill="#f2d500"
          />
          <path
            className="cls-1"
            d="M333.25,30.26a6.16,6.16,0,0,1,1.65,4.46V72.06a5.52,5.52,0,0,1-1.51,4.11,6,6,0,0,1-7.93,0,5.5,5.5,0,0,1-1.54-4.11V51.88L316.1,66.29a8.84,8.84,0,0,1-2.47,3.12,5.6,5.6,0,0,1-6.18,0A8.73,8.73,0,0,1,305,66.29l-7.76-14V72.06a5.51,5.51,0,0,1-1.54,4.08,5.52,5.52,0,0,1-9.51-4.08V34.72a6.1,6.1,0,0,1,1.68-4.46,6,6,0,0,1,7.66-.65,8.37,8.37,0,0,1,2.54,3l12.62,23.75,12.56-23.75c1.42-2.74,3.34-4.12,5.77-4.12A5.63,5.63,0,0,1,333.25,30.26Z"
            fill="#f2d500"
          />
          <path
            className="cls-1"
            d="M345.33,75.76a5.85,5.85,0,0,1-1.51-4.32V34.72a5.85,5.85,0,0,1,1.51-4.32,5.85,5.85,0,0,1,4.33-1.51h23.75a7,7,0,0,1,4.46,1.23,4.37,4.37,0,0,1,1.51,3.57q0,4.94-6,4.94h-17.3v9.2H372q6,0,6,4.87a4.33,4.33,0,0,1-1.51,3.57A6.91,6.91,0,0,1,372,57.51H356.11v10h17.3c4,0,6,1.64,6,4.94A4.38,4.38,0,0,1,377.87,76a7,7,0,0,1-4.46,1.23H349.66A5.85,5.85,0,0,1,345.33,75.76Z"
            fill="#f2d500"
          />
          <path
            className="cls-1"
            d="M84.85,37.47A42.58,42.58,0,1,0,36.69,84.75a43.92,43.92,0,0,0,5.89.4,42.91,42.91,0,0,0,5.22-.31V55c0-.86,0-.86.87-.86H54.8c.91,0,.91,0,.91.89V83.09a42.23,42.23,0,0,0,11.11-5.51V55c0-.87,0-.87.87-.87H72c.92,0,1.85,0,2.78,0a10.5,10.5,0,0,0,10-9.12C85,42.57,84.87,37.53,84.85,37.47ZM51,43v0H29.44a10.53,10.53,0,0,0-10.68,9.33,65.13,65.13,0,0,0-.12,7.13c0,.47.33.41.61.41,3.29,0,6.58,0,9.87,0,.52,0,.64-.2.63-.67,0-1.47,0-3,0-4.42,0-.52.16-.7.69-.69q2.74,0,5.49,0c.62,0,.79.19.79.79,0,2.89,0,5.77,0,8.66V73.62a31.59,31.59,0,1,1,37.09-36c0,.08,1.08,5.37-.84,5.37Z"
            fill="#f2d500"
          />
          <circle
            className="cls-1"
            cx="42.2"
            cy="33.77"
            r="5.5"
            fill="#f2d500"
          />
          <circle
            className="cls-1"
            cx="60.26"
            cy="33.77"
            r="5.5"
            fill="#f2d500"
          />
        </g>
      </g>
    </SvgIcon>
  );
}
